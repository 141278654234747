export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_API_URLS = {
  GET_LOCATION: "/api/canco/store/",
  GET_CARDS: "/api/canco_loyalty/cards/",
  GET_CARD_TRANSACTION: "/api/canco_loyalty/cards/",
  GET_USER_DETAIL: "/api/accounts/user/",
  GET_BLOG: "/api/cancoblog/",
  GET_BANNER: "/api/canco/banner/",
  GET_PROMOTIONS: "/api/webpromotions/web_promotions"
};

export const POST_API_URLS = {
  ACKROO_LOGIN: "/api/accounts/login/",
  REGISTER_USER: "/api/accounts/register/",
  RESET_PASSWORD: "/api/accounts/reset_password/",
  NEW_PASSWORD: "/api/accounts/reset_password/confirm/",
  ADD_CARD: "/api/canco_loyalty/cards/",
  BALANCE_TRANSFER: "/api/canco_loyalty/cards/transfer/",
  VENDOR_FORM: "/api/userforms/vendorforms/",
  FLEET_CARD_MEMBER_FORM: "/api/userforms/fleetcards/",
  CONTACT_FORM: "/api/userforms/contact_us/",
  SURVEY_FORM: "/api/userforms/surveys/",
  UNREGISTER_USER: "/api/userforms/unregister-canco-user/",
};
