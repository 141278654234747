export const routes = {
  HOME: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  FIND_LOCATION: "/find-location",
  LOGIN: "/login",
  CANCO_CASH_LOYALTY: "/canco-cash-loyalty",
  REGISTER: "/register",
  IN_STORE: "/in-store",
  WORK_WITH_US: "/work-with-us",
  BUSINESS: "/business",
  CANCO_FLEET_CARD: "/canco-xpress-fleet-card",
  CANCO_CONNECT: "/canco-connect",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  BECOME_VENDOR: "/become-vendor",
  BECOME_DEALER: "/become-dealer",
  BECOME_REALTOR: "/become-realtor",
  BECOME_FLEET_CARD_MEMBER: "/become-fleet-card-member",
  SURVEY: "/survey",
  BLOGS: "/canco-community-care",
  REQUEST_ACOOUNT_DEACTIVATION: "/account-deletion-and-data-removal-policy",
  SUCCESS_ACOOUNT_DEACTIVATION: "/success-account-deletion-request",
  ROAD_TRIP_RULES: "/road-trip",
  SINGLE_BLOG: "/canco-community-care/:blogId",
  CAREERS: "https://canco.bamboohr.com/careers",
  TERMS_CONDITION: "/terms-and-condition",
  EMAIL_CONFIRMATION: "/email-confirmation",
  EDIT_USER_PROFILE: "/edit-user-profile",
  THANK_YOU: "thank-you",
  G_PLAY:
    "https://play.google.com/store/apps/details?id=com.ackroo.canco&hl=en&gl=US",
  APPLE_STORE: "https://apps.apple.com/kh/app/canco-petroleum/id1523902717",
  FACEBOOK: "https://www.facebook.com/cancopetroleum",
  INSTAGRAM: "https://www.instagram.com/cancopetroleum",
  LINKEDIN: "https://www.linkedin.com/company/canco-petroleum/",
};
